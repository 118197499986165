import React, { useState } from 'react';
import { CloseButton, Container, Offcanvas } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';

//image here

import BrandImage from '../../assets/images/brandimage.jpg';

const Navbar = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [navInfo, setNavInfo] = useState(false);
  return (
    <>
      <section className='absnavbar'>
        <Container>
          <nav className=' navWrapper'>
            <Link to='/'>
              <figure className='navWrapper__navBrand'>
                <img src={BrandImage} alt='' />
              </figure>
            </Link>

            <ul className='navWrapper__navItems'>
              <li className='navWrapper__navItems--items'>
                <NavLink
                  className={`${
                    location.pathname === '/' ? 'activeSidebar' : 'notActive'
                  }`}
                  to='/'
                >
                  Home
                </NavLink>
              </li>
              <li className='navWrapper__navItems--items'>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'activeSidebar' : 'notActive'
                  }
                  to='/news'
                >
                  News
                </NavLink>
              </li>
              <li className='navWrapper__navItems--items'>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'activeSidebar' : 'notActive'
                  }
                  to='/gallery'
                >
                  Gallery
                </NavLink>
              </li>
              <li className='navWrapper__navItems--items'>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'activeSidebar' : 'notActive'
                  }
                  to='/contact-us'
                >
                  Contact
                </NavLink>
              </li>
              {/* <li className='navWrapper__navItems--items'>
              <NavLink
                className={(navData) =>
                  navData.isActive ? 'activeSidebar' : 'notActive'
                }
                to='/news'
              >
                News
              </NavLink>
            </li> */}
            </ul>

            <section className='navWrapper__mdNav'>
              <section className='nav_mdInfo'>
                {
                  <button
                    className='open_mdNav'
                    // onClick={() => setNavInfo(true)}
                    onClick={handleShow}
                  >
                    &#9776;
                  </button>
                }

                <Offcanvas show={show} onHide={handleClose} placement='end'>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menubar</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <section
                      className={`mdNav_list_container ${
                        navInfo && ' showmdNav'
                      }`}
                    >
                      <ul className='mdNav_list'>
                        <li
                          className='navWrapper__navItems--items'
                          onClick={handleClose}
                        >
                          <NavLink
                            className={`${
                              location.pathname === '/'
                                ? 'activeSidebarMob'
                                : 'notActiveMob'
                            }`}
                            to='/'
                          >
                            Home
                          </NavLink>
                        </li>
                        <li
                          className='navWrapper__navItems--items'
                          onClick={handleClose}
                        >
                          <NavLink
                            className={(navData) =>
                              navData.isActive
                                ? 'activeSidebarMob'
                                : 'notActiveMob'
                            }
                            to='/news'
                          >
                            News
                          </NavLink>
                        </li>
                        <li
                          className='navWrapper__navItems--items'
                          onClick={handleClose}
                        >
                          <NavLink
                            className={(navData) =>
                              navData.isActive
                                ? 'activeSidebarMob'
                                : 'notActiveMob'
                            }
                            to='/gallery'
                          >
                            Gallery
                          </NavLink>
                        </li>
                        <li
                          className='navWrapper__navItems--items'
                          onClick={handleClose}
                        >
                          <NavLink
                            className={(navData) =>
                              navData.isActive
                                ? 'activeSidebarMob'
                                : 'notActiveMob'
                            }
                            to='/contact-us'
                          >
                            Contact
                          </NavLink>
                        </li>
                      </ul>
                    </section>
                  </Offcanvas.Body>
                </Offcanvas>
              </section>
            </section>
          </nav>
        </Container>
      </section>
    </>
  );
};

export default Navbar;
