import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { BiDonateBlood } from 'react-icons/bi';
import { BiHappyAlt } from 'react-icons/bi';
import { BiHelpCircle } from 'react-icons/bi';
import { BsPersonCheck } from 'react-icons/bs';
import { TbHeartHandshake } from 'react-icons/tb';

const WhatWeDo = () => {
  return (
    <Container>
      <section className='whatWrapper'>
        {' '}
        <h1>What are we Doing</h1>{' '}
        <Row>
          <Col md={4}>
            <section className='whatCard'>
              <span className='whatCard-icon'>
                {' '}
                <BiDonateBlood />{' '}
              </span>
              <h2 className='whatCard-title'>Blood Donation</h2>
              <p className='whatCard-desc'>
                {' '}
                We are conducting a Blood Donation program every year,
                collaborating with the Red Cross.
              </p>
            </section>{' '}
          </Col>
          <Col md={4}>
            <section className='whatCard'>
              <span className='whatCard-icon'>
                {' '}
                <BiHelpCircle />{' '}
              </span>
              <h2 className='whatCard-title'>Help Makawanpur</h2>
              <p className='whatCard-desc'>
                We have various programs in place to help some NGOs in
                Makawanpur to support the needy people.
              </p>
            </section>{' '}
          </Col>
          <Col md={4}>
            <section className='whatCard'>
              <span className='whatCard-icon'>
                {' '}
                <BsPersonCheck />{' '}
              </span>
              <h2 className='whatCard-title'>Career Counseling</h2>
              <p className='whatCard-desc'>
                We are providing career counseling to the newly graduated
                students in Australia through phone and through events.
              </p>
            </section>{' '}
          </Col>
          <Col md={4}>
            <section className='whatCard'>
              <span className='whatCard-icon'>
                {' '}
                <BiHappyAlt />{' '}
              </span>
              <h2 className='whatCard-title'>Entertainment for cause</h2>
              <p className='whatCard-desc'>
                We have started to collect some funds to help needy people in
                Australia and in Makawanpur through some entertainment programs
                like cultural, festivals, and events.
              </p>
            </section>{' '}
          </Col>
          <Col md={4}>
            <section className='whatCard'>
              <span className='whatCard-icon'>
                {' '}
                <TbHeartHandshake />{' '}
              </span>
              <h2 className='whatCard-title'>Meet and Greet</h2>
              <p className='whatCard-desc'>
                We are organizing one open invitation Meet and Greet BBQ program
                each year in New South Wales (NSW). We believe this program will
                help to build a strong network among our community.
              </p>
            </section>{' '}
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default WhatWeDo;
