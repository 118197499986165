import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselHero from '../../components/CarouselHero/index';
import AboutImage from '../../assets/images/about_image.jpg';
import CharityImg from '../../assets/images/Charity Pic 1.jpg';
import CharityImg3 from '../../assets/images/Charity Pic 3.jpg';
import CharityImg6 from '../../assets/images/oct dinner 3 (1).jpg';
import Widget from '../../components/Widget';
import WhatWeDo from '../../components/WhatWeDo';
import { Link } from 'react-router-dom';

const Home = () => {
  const count = [1, 1, 1, 1, 11, 11, 5, 1, 1, 11, 1, 5];
  return (
    <>
      {' '}
      <section role='hero'>
        <div className='herocontainer'>
          <CarouselHero />
          <section className='carCaption'>
            <h1 className='carCaption-title'>
              Let’s unite each other for helping hands
            </h1>
            <Link to='/contact-us'>
              <button className='bton bton--md bton--secondary mt-3'>
                Contact&nbsp;Us
              </button>
            </Link>
          </section>
        </div>
      </section>
      <Container>
        <section className='statsWrapper'>
          <Row className='justify-content-center'>
            <Col md={2}>
              <article>
                <div className='statsWrapper-number'>100 +</div>
                <div className='statsWrapper-label'>members</div>
              </article>
            </Col>
            <Col md={2}>
              <article>
                <div className='statsWrapper-number'>20 +</div>
                <div className='statsWrapper-label'>programs</div>
              </article>
            </Col>
            <Col md={2}>
              <article>
                <div className='statsWrapper-number'>15 +</div>
                <div className='statsWrapper-label'>Events</div>
              </article>
            </Col>
          </Row>
        </section>

        <section className='aboutWrapper'>
          <header className='headTitle'>
            {/* <div className='headTitle-sub'>WHO is HDEAC ?</div> */}
            <h1 className='headTitle-main'>About MCA </h1>
          </header>

          <Row className='justify-content-between'>
            <Col md={5}>
              <section className='aboutContent'>
                <p>
                  Makawanpur Community Australia (MCA) is a non-profit and
                  non-political organization, driven by a group of like-minded
                  people with a great passion for social connectivity and
                  service to residents of Makawanpursocial reform. <br /> <br />
                  Makawanpur Community Australia (MCA) was established in 2018
                  to build a unity among Makawanpure residing in Australia and
                  be a helping hand to the needy people in Makawanpur, Nepal.
                  Makawanpur is one of the beautiful districts of Nepal which is
                  the part of Bagmati Province. Hetauda is the district
                  headquarter of Makawanpur and is also the capital headquarter
                  of Bagmati Province. <br /> <br />
                  MCA empowers the community through building a strong network
                  among the Makawanpure and providing the helping hands to the
                  needy people in Australia and in Nepal. MCA focuses on
                  providing community service in different sectors like
                  Education, Health, Entertainment, Airport Pickups, Blood
                  Donation and school construction in Nepal. We are also
                  providing career counseling towards the newly graduated
                  students in Australia.
                </p>
              </section>
            </Col>

            <Col md={6}>
              <figure className='aboutImage'>
                <img src={AboutImage} alt='' />
              </figure>
            </Col>
          </Row>
        </section>
      </Container>
      <section className='charityWrapper'>
        <Container>
          {/* <h1 className='charityWrapper-title'>MCA For Community</h1>
          <p className='charityWrapper-desc'>
            To promote education, Health, equal living standards, and equal
            rights in Makawanpur remote areas. To empower individuals,
            especially the underprivileged and disadvantaged people in
            Makawanpur. To create a channel for connectivity and social
            interaction while helping all Makawanpurey’s in Australia and in
            Nepal.
          </p> */}

          <Row className='mt-5 mb-5 justify-content-between'>
            <Col md={3}>
              <h2 className='charityWrapper-title'> Our Mission</h2>
              <p className='missVisDisc'>
                We are committed to bring some social changes primarily focusing
                on basic needs of human beings that include Food, Shelter, and
                Education for underprivileged people in order to uplift their
                living standards.
              </p>
            </Col>
            <Col md={4}>
              <h2 className='charityWrapper-title'> Our Vision</h2>
              <p className='missVisDisc'>
                Our vision is to create an organization that represents
                residents of Makawanpur in Australia. We do this by providing
                support and assistance to residents of Makawanpur in Australia
                and also in Makawanpur. We visualize a prosperous future of our
                nation with an all-round development by bringing our all the
                Makawanpureys on one platform globally.
              </p>
            </Col>
            <Col md={4}>
              <h2 className='charityWrapper-title'> Our Objectives</h2>
              <ul className='objList'>
                <li>
                  To promote education, Health, equal living standards, and
                  equal rights in Makawanpur remote areas.
                </li>
                <li>
                  To empower individuals, especially the underprivileged and
                  disadvantaged people in Makawanpur.
                </li>
                <li>
                  To create a channel for connectivity and social interaction
                  while helping all Makawanpurey’s in Australia and in Nepal.
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg} alt='' />
              </figure>
            </Col>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg6} alt='' />
              </figure>
            </Col>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg3} alt='' />
              </figure>
            </Col>
          </Row>
        </Container>
      </section>
      <WhatWeDo />
      <Widget />
    </>
  );
};

export default Home;
