import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import BlogPageLoader from '../../components/Skeletons/BlogPageLoader';
// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   PinterestShareButton,
//   TwitterShareButton,
//   PinterestIcon,
//   LinkedinShareButton,
//   LinkedinIcon,
//   FacebookShareCount,
//   PinterestShareCount,
// } from 'react-share';
const BlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { slug } = useParams();
  const GET_SINGLE_BLOG = gql`
    query Blogs($id: ID!) {
      blog(id: $id, idType: SLUG) {
        title
        slug
        date

        blog {
          blog
          blogCategory {
            name
          }
          heroImage {
            sourceUrl
          }
        }
      }
    }
  `;
  const { loading, data, error } = useQuery(GET_SINGLE_BLOG, {
    variables: { id: slug },
  });

  if (loading) return <BlogPageLoader />;
  // if (loading) return <p>blog page loader</p>;
  if (error) return <p>Error : </p>;

  return (
    <div>
      <Container>
        <section className=' blogContainer'>
          <section className='allBolgs'>
            <Link to='/news'>
              <button className='bton bton--sm bton--ghost'>All News</button>
            </Link>
          </section>
          <h1 className=''>{data.blog.title}</h1>
          <span>
            {' '}
            <i>
              Category: {data.blog.blog.blogCategory.name} .{' '}
              {data.blog.date.substring(0, 10)}{' '}
            </i>
          </span>

          {/* Share buttons */}
          {/* <div>
            <FacebookShareButton url={`https://www.facebook.com/MyRucir`}>
              <FacebookIcon size={38} round={true} />
            </FacebookShareButton>

            <LinkedinShareButton
              url={`https://www.linkedin.com/company/rucir/`}
            >
              <LinkedinIcon size={38} round={true} />
            </LinkedinShareButton>

            <TwitterShareButton url={`https://twitter.com/RUCIR_WELLNESS`}>
              <TwitterIcon size={38} round={true} />
            </TwitterShareButton>
            <PinterestShareButton
              url={`https://www.pinterest.com/rucirwellness/`}
            >
              <PinterestIcon size={38} round={true} />
            </PinterestShareButton>
          </div> */}
          {/* Share buttons */}
          <p></p>
          <div>
            {/* <img
              src={data.blog.blog.heroImage.sourceUrl}
              alt={data.blog.title}
            />{' '} */}
          </div>

          <figure>
            <img src={data.blog.blog.heroImage.sourceUrl} alt='' />
          </figure>
          <p dangerouslySetInnerHTML={{ __html: data.blog.blog.blog }} />
        </section>
      </Container>
    </div>
  );
};

export default BlogPage;
