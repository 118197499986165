import React from 'react';
import { Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import ContactForm from '../../components/ContactForm';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={5}>
            <section className='formWrapper'>
              <h1>
                Lets Talk, <strong className='cpr'>Say Hi !</strong>{' '}
              </h1>
              <p>
                For inquiries, please kindly drop your contact details and brief
                message here.
              </p>

              <ContactForm />
            </section>
          </Col>
          <Col md={7}>
            <section className='formWrapper'>
              <h1>Our Location</h1>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.538941906338!2d151.13631701503368!3d-33.952984680633065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b9ff89fff71b%3A0x42899abd14252d28!2s5%2F38-42%20Bay%20St%2C%20Rockdale%20NSW%202216%2C%20Australia!5e0!3m2!1sen!2snp!4v1673494964179!5m2!1sen!2snp'
                width='100%'
                height='500'
              ></iframe>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
