import { useQuery } from '@apollo/react-hooks';
import React, { useContext } from 'react';
// import MemberContext from '../context/MemberContext';
import CarouselSkeleton from '../components/Skeletons/CarouselSkeleton';

const Query = ({ children, query, id }) => {
  // const memberContext = useContext(MemberContext);
  // const { setMember, members } = memberContext;

  const { data, loading, error } = useQuery(query, {
    variables: { id: id },
  });

  if (loading) return <CarouselSkeleton />;

  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  // if (data.page.id.toString() === 'cG9zdDoxNQ==') {
  //   if (members.length === 0) setMember(data.page.members.memberSection);
  // }
  return children({ data });
};

export default Query;
