import React from 'react';
import { Route, Routes } from 'react-router-dom';

//components

import Footer from '../../components/Footer';
// import Members from '../Members';
import NavBar from '../../components/Navbar';

//pages

import Home from '../Home';
import Contact from '../Contact';
// import MemberContext from '../../context/MemberContext';
// import MemberState from '../../context/MemberState';
// import ApplicationForm from '../ApplicationForm';
import Blogs from '../Blogs';
import BlogPage from '../BlogPage';
import Gallery from '../Gallery';

const App = () => {
  return (
    <>
      <NavBar />

      <main>
        <section className='contentWrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            {/* <Route path='/members' element={<Members />} /> */}
            <Route path='/contact-us' element={<Contact />} />
            {/* <Route path='/application' element={<ApplicationForm />} /> */}
            <Route path='/news' element={<Blogs />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/news/:slug' element={<BlogPage />} />
          </Routes>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default App;
