import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Widget() {
  return (
    <Container>
      <section className='flex-between widgetWrapper'>
        <h2>Want to Join our communnity and become one of us ?</h2>
        <Link to='/contact-us' rel='noreferrer noopener'>
          <button className='bton bton--lg bton--secondary'>
            Contact&nbsp;Us
          </button>
        </Link>
      </section>
    </Container>
  );
}

export default Widget;
