import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section role='Footer'>
        <Container>
          <Row className='justify-content-between'>
            <Col md={4}>
              <section className='foooterInfo'>
                <header className='Links-title'>
                  <h2>About MCA</h2>
                </header>
                <p>
                  Makawanpur Community Australia (MCA) is a non-profit and
                  non-political organization, driven by a group of like-minded
                  people with a great passion for social connectivity and
                  service to residents of Makawanpursocial reform.
                </p>
              </section>
            </Col>
            <Col md={2}>
              <section className='Links'>
                <header className='Links-title'>
                  <h2>Quick links</h2>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/news'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i> News
                    </Link>
                  </li>
                  {/* <li className='Links-box-item'>
                    {' '}
                    <Link to='/products'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i>About Us
                    </Link>
                  </li> */}
                  <li className='Links-box-item'>
                    {' '}
                    <a href='/gallery'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i>Gallery
                    </a>
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/contact-us'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i>
                      Contact
                    </Link>
                  </li>
                </ul>
              </section>
              <section className='socialMedia'>
                <header className='socialMedia-title'>
                  <h2>Find Us On</h2>
                </header>

                <section className='socialWrapper'>
                  <a
                    href={
                      'https://www.facebook.com/Makawanpur-Community-Australia-MCA-417699572112619/'
                    }
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <i class='fa fa-facebook-f'></i>
                    </div>
                  </a>

                  {/* <a href={'/'} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-instagram'></i>
                    </div>
                  </a>
                  <a href={'/'} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-twitter'></i>
                    </div>
                  </a> */}
                </section>
              </section>
            </Col>

            <Col md={4}>
              <section className='contact'>
                <header className='contact-title'>
                  <h2>Reach Us</h2>
                </header>

                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Mailing Address</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        Address: 5/38-42 Bay Street, Rockdale, NSW 2216
                      </div>
                    </div>
                  </li>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Phone</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        +61 415676577 | 0421 729 343
                      </div>
                    </div>
                  </li>

                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-envelope'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Email</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        <a
                          href='mailto:
sample@hotmail.com'
                        >
                          <span style={{ color: 'white' }}>
                            info.mcahetauda@gmail.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
          </Row>

          <hr />
          <section className='copyright'>
            MCA &copy; {new Date().getFullYear()} All Rights Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
