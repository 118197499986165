import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CAROUSEL_QUERY } from '../../queries/carousals';
import Query from '../../components/Query';

const Gallery = () => {
  return (
    <Container>
      <div className='GalleryWrapper'>
        <h1 className='mb-5'>Our Gallery Section</h1>
        <Query query={CAROUSEL_QUERY} id={'cG9zdDo0MQ=='}>
          {({ data: { page } }) => {
            return (
              <>
                <Row>
                  {page?.carousel.carousel.map((a) => {
                    return (
                      <Col md={4}>
                        <section className='galleryItem'>
                          <img src={a.sourceUrl} alt='' />
                        </section>
                      </Col>
                    );
                  })}
                </Row>
              </>
            );
          }}
        </Query>
      </div>
    </Container>
  );
};

export default Gallery;
